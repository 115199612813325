





















































































import {Component, Vue} from 'vue-property-decorator'
import {getStaticLaunchpadConf} from '@/clients/cpinblocks'

@Component
export default class landingPage extends Vue {
	private projects: any[] = []

	private slide = {
		title: this.$t('landingPage.header_text'),
		subtitle: this.$t('landingPage.subheader_text'),
		card: {}
	}

	openInNewTab(url: string): void {
		window.open(url, '_blank')
	}

	private async mounted(): Promise<void> {
		this.projects = (await getStaticLaunchpadConf()).filter((p) => p.statusHomepage === 'live')
		this.slide.card = this.projects.shift()
	}

	private redirectTo(): void {
		window.location.href = window.location.origin + '/dashboard'
	}

	private redirectToRegister(): void {
		this.$keycloak.init({onLoad: 'check-sso'})
		this.$keycloak.register()
	}
}
